import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const SharedPrediction = () => {
    const { shareId } = useParams();
    const navigate = useNavigate();
    const [prediction, setPrediction] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchSharedPrediction = async () => {
            try {
                const response = await fetch(`${API_BASE_URL}/predictions/shared/${shareId}`);
                if (!response.ok) throw new Error('Prediction not found');
                const data = await response.json();
                setPrediction(data);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        fetchSharedPrediction();
    }, [shareId]);

    const getResolutionText = (chance, didHappen) => {
        const conjunction = (chance >= 70 && didHappen) || 
                          (chance <= 30 && !didHappen) || 
                          (chance > 30 && chance < 70) ? 'and' : 'but';
        
        const showIcon = chance <= 30 || chance >= 70;
        let icon = '';
        let iconColor = '';
        
        if (showIcon) {
            icon = didHappen ? '✓' : '✕';
            iconColor = didHappen ? '#4CAF50' : '#f44336';
        }
        
        return (
            <p style={{ 
                margin: '20px 0', 
                fontSize: '1.1em',
                display: 'flex',
                alignItems: 'center',
                gap: '8px'
            }}>
                {showIcon && (
                    <span style={{ 
                        color: iconColor,
                        fontSize: '1.2em'
                    }}>
                        {icon}
                    </span>
                )}
                I predicted a {chance}% chance {conjunction} it {didHappen ? 'did' : "didn't"} happen
            </p>
        );
    };

    if (loading) return <div className="prediction-details">Loading...</div>;

    if (error || !prediction) {
        return (
            <div className="prediction-details">
                <h2>Prediction not found</h2>
                <button onClick={() => navigate('/')} className="create-prediction-btn">
                    Create Your Own Prediction
                </button>
            </div>
        );
    }

    const userText = prediction.username 
        ? `${prediction.username} ${prediction.resolved ? 'predicted' : 'predicts'} that` 
        : `Someone ${prediction.resolved ? 'predicted' : 'predicts'} that`;

    const currentChance = prediction.current_chance || prediction.currentChance;

    return (
        <div className="prediction-details">
            <p className="small-text">{userText}</p>
            
            {prediction.resolved ? (
                <>
                    <h1 className="secondary-prediction-text">
                        there was a {currentChance}% chance of {prediction.title}
                    </h1>
                    {getResolutionText(currentChance, prediction.resolution_value)}
                </>
            ) : (
                <h1 className="secondary-prediction-text">
                    there's a {currentChance}% chance of {prediction.title}
                </h1>
            )}

            <div className="share-section">
                <button onClick={() => navigate('/')} className="create-prediction-btn">
                    Create Your Own Prediction
                </button>
            </div>
        </div>
    );
};

export default SharedPrediction;