import React from 'react';
import { Link } from 'react-router-dom';

const PredictionList = ({ predictions }) => {
  const handleShare = (e, shareId) => {
    e.preventDefault();
    e.stopPropagation();
    const shareUrl = `${window.location.origin}/share/${shareId}`;
    navigator.clipboard.writeText(shareUrl);
    alert('Share link copied to clipboard!');
  };

  return (
    <div className="prediction-listed">
      {predictions.length === 0 ? (
        <p></p>
      ) : (
        predictions.map(prediction => {
          // Handle both camelCase and snake_case
          const chance = prediction.current_chance || prediction.currentChance;
          
          return (
            <Link 
              key={prediction.id}
              to={`/prediction/${prediction.id}`}
              className="prediction prediction-link"
            >
              <p className="small-text">
                I predict there's a
              </p>
              
              <h3 className="main-prediction-text">
                {chance}% chance of {prediction.title}
              </h3>

              <button 
                className="list-share-button"
                onClick={(e) => handleShare(e, prediction.shareId || prediction.share_id)}
              >
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  height="20px" 
                  viewBox="0 -960 960 960" 
                  width="20px" 
                  fill="#5f6368"
                >
                  <path d="M680-80q-50 0-85-35t-35-85q0-6 3-28L282-392q-16 15-37 23.5t-45 8.5q-50 0-85-35t-35-85q0-50 35-85t85-35q24 0 45 8.5t37 23.5l281-164q-2-7-2.5-13.5T560-760q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35q-24 0-45-8.5T598-672L317-508q2 7 2.5 13.5t.5 14.5q0 8-.5 14.5T317-452l281 164q16-15 37-23.5t45-8.5q50 0 85 35t35 85q0 50-35 85t-85 35Z"/>
                </svg>
              </button>
            </Link>
          );
        })
      )}
    </div>
  );
};

export default PredictionList;