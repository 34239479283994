import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const UPDATE_INTERVAL = 3600; // 1 hour in milliseconds

const PredictionDetails = ({ predictions, setPredictions, updatePrediction, deletePrediction }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const prediction = predictions.find(p => String(p.id) === id);
  const [newChance, setNewChance] = useState(() => 
    prediction?.current_chance ?? prediction?.currentChance ?? 50
  );
  const [isUpdateOpen, setIsUpdateOpen] = useState(false);
  const [showAllHistory, setShowAllHistory] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (prediction) {
      setNewChance(prediction.current_chance ?? prediction.currentChance ?? 0);
    }
  }, [prediction]);
console.log('Raw prediction:', prediction);
  useEffect(() => {
    if (prediction?.history?.length && !prediction.resolved) {
      const lastUpdate = prediction.history[prediction.history.length - 1]?.date || new Date();
      const lastUpdateDate = new Date(lastUpdate);
      const updateInterval = setInterval(() => {
        const now = new Date();
        const diffMs = now - lastUpdateDate;
        const waitMs = Math.max(UPDATE_INTERVAL - diffMs, 0);
        setCountdown(Math.ceil(waitMs / 60000));
      }, 1000);

      return () => clearInterval(updateInterval);
    }
  }, [prediction]);

  const formatDate = (date) => {
    return new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    }).format(new Date(date));
  };

  const handleChanceKeyDown = (e) => {
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault();
      const direction = e.key === 'ArrowUp' ? 1 : -1;
      const nextValue = Math.min(100, Math.max(0, Math.round(newChance / 10) * 10 + (direction * 10)));
      setNewChance(nextValue);
    }
  };

  const handleChanceChange = (e) => {
    const rawValue = e.target.value;
    if (rawValue === '') {
      setNewChance(0);
      return;
    }

    const value = Number(rawValue);
    if (!isNaN(value) && value >= 0 && value <= 100) {
      setNewChance(value);
    }
  };

const handleUpdate = async () => {
  try {
    const updatedPrediction = {
      ...prediction,
      currentChance: newChance,
      current_chance: newChance,
      history: [...(prediction.history || []), { chance: newChance, date: new Date() }]
    };
    
    const result = await updatePrediction(updatedPrediction);
    
    // Update the predictions list in the parent component
    setPredictions(prev => 
      prev.map(p => p.id === prediction.id ? {
        ...result,
        currentChance: result.current_chance, // Ensure we have both formats
        current_chance: result.current_chance
      } : p)
    );
    
    setIsUpdateOpen(false);
  } catch (error) {
    console.error('Error updating prediction:', error);
  }
};

  const getResolutionText = (chance, didHappen) => {
    const conjunction = (chance >= 70 && didHappen) || 
                       (chance <= 30 && !didHappen) || 
                       (chance > 30 && chance < 70) ? 'and' : 'but';
    
    const showIcon = chance <= 30 || chance >= 70;
    let icon = '';
    let iconColor = '';
    
    if (showIcon) {
      icon = didHappen ? '✓' : '✕';
      iconColor = didHappen ? '#4CAF50' : '#f44336';
    }
    
    return (
      <p style={{ 
        margin: '20px 0', 
        fontSize: '1.1em',
        display: 'flex',
        alignItems: 'center',
        gap: '8px'
      }}>
        {showIcon && (
          <span style={{ 
            color: iconColor,
            fontSize: '1.2em'
          }}>
            {icon}
          </span>
        )}
        I predicted a {chance}% chance {conjunction} it {didHappen ? 'did' : "didn't"} happen
      </p>
    );
  };
  
  const handleDelete = () => {
    const confirmed = window.confirm('Are you sure you want to delete this prediction? This action cannot be undone.');
    if (confirmed) {
      deletePrediction(prediction.id);
      navigate('/');
    }
  };

  const handleShare = () => {
    const shareUrl = `${window.location.origin}/share/${prediction.shareId || prediction.share_id}`;
    navigator.clipboard.writeText(shareUrl);
    alert('Share link copied to clipboard!');
  };

  const handleResolve = async (outcome) => {
    if (loading) return;
    
    const confirmed = window.confirm(
      `Are you sure ${outcome ? 'this came true' : 'this did not come true'}? `
    );
    
    if (!confirmed) return;
    
    try {
      setLoading(true);
      const updatedPrediction = {
        ...prediction,
        resolved: true,
        resolution_value: outcome,
        resolved_at: new Date().toISOString()
      };
      
      const result = await updatePrediction(updatedPrediction);
      setPredictions(prev => 
        prev.map(p => p.id === prediction.id ? {
          ...result,
          currentChance: result.current_chance,
          resolution_value: result.resolution_value === 1  // Convert back to boolean
        } : p)
      );
      setIsUpdateOpen(false);
    } catch (error) {
      console.error('Error resolving prediction:', error);
    } finally {
      setLoading(false);
    }
  };

  if (!prediction) {
    return (
      <div className="prediction-details">
        <h2>Prediction not found</h2>
        <p>This prediction may have been deleted or the link might be incorrect.</p>
        <button onClick={() => navigate('/')} className="back-button">
          Back to Home
        </button>
      </div>
    );
  }

  const displayedHistory = showAllHistory 
    ? (prediction.history || [])
    : (prediction.history || []).slice(-9);

  const hasMoreHistory = (prediction.history || []).length > 9;
  const currentChance = prediction.current_chance || prediction.currentChance;
  const canUpdate = countdown === 0;

  return (
    <div className="prediction-details">
      <div className="header-buttons">
        <button onClick={() => navigate('/')} className="icon-button">
          <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 0 20 20" width="20px" fill="#5F6368">
            <path d="M2.9,10.8l4.2,4.2L6,16l-6-6l6-6l1.1,1.1L2.9,9.2H12v1.5H2.9z"/>
          </svg>
          <span className="back-text">Back</span>
        </button>
        <button onClick={handleShare} className="icon-button">
          <span className="share-text">Share prediction</span>
          <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#5F6368">
            <path d="M680-80q-50 0-85-35t-35-85q0-6 3-28L282-392q-16 15-37 23.5t-45 8.5q-50 0-85-35t-35-85q0-50 35-85t85-35q24 0 45 8.5t37 23.5l281-164q-2-7-2.5-13.5T560-760q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35q-24 0-45-8.5T598-672L317-508q2 7 2.5 13.5t.5 14.5q0 8-.5 14.5T317-452l281 164q16-15 37-23.5t45-8.5q50 0 85 35t35 85q0 50-35 85t-85 35Z"/>
          </svg>
        </button>
      </div>

      <p className="small-text">{prediction.resolved ? 'I predicted that' : 'I predict that'}</p>

      {prediction.resolved ? (
        <>
          <h1 className="secondary-prediction-text">
            there was a {currentChance}% chance of {prediction.title}
          </h1>
          {getResolutionText(currentChance, prediction.resolution_value)}
        </>
      ) : (
        <h1 className="secondary-prediction-text">
          there's a {currentChance}% chance of {prediction.title}
        </h1>
      )}

        <div className="prediction-history">
        {!prediction.resolved ? (
          <>
            <button 
              onClick={() => setIsUpdateOpen(!isUpdateOpen)} 
              className="text-button"
            >
              {isUpdateOpen ? 'Done' : 'Update prediction'}
            </button>

            {isUpdateOpen && (
              <div className="update-section">
                <p className="small-text">Did "{prediction.title}" come true?</p>
                <div className="chance-group" style={{ justifyContent: 'flex-start', marginBottom: '40px' }}>
                  <button
                    onClick={() => handleResolve(true)}
                    className="update-prediction-btn"
                    style={{ backgroundColor: '#2b2828', marginRight: '10px' }}
                    disabled={loading}
                  >
                    Yes
                  </button>
                  <button
                    onClick={() => handleResolve(false)}
                    className="update-prediction-btn"
                    style={{ backgroundColor: '#2b2828' }}
                    disabled={loading}
                  >
                    No
                  </button>
                </div>

                <h4>Prediction History:</h4>
                
                {canUpdate ? (
                  <>
                    <p className="small-text">Right now I think the chance is</p>
                    <div className="chance-group">
                      <input
                        type="number"
                        className="chance-input"
                        value={newChance}
                        onChange={handleChanceChange}
                        onFocus={(e) => e.target.select()}
                        onKeyDown={handleChanceKeyDown}
                        min="0"
                        max="100"
                      />
                      <span>%</span>
                      <div className="update-buttons">
                        <button 
                          onClick={handleUpdate} 
                          className="update-prediction-btn"
                          disabled={loading}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <p className="small-text">
                    You can update again in {countdown} {countdown === 1 ? 'minute' : 'minutes'}
                  </p>
                )}

                <ul className="history-list">
                  {[...displayedHistory].reverse().map((entry, index) => (
                    <li key={index}>
                      <strong>{entry.chance}%</strong> on {formatDate(entry.date)}
                    </li>
                  ))}
                </ul>
                {hasMoreHistory && (
                  <button 
                    onClick={() => setShowAllHistory(!showAllHistory)} 
                    className="show-all-btn"
                  >
                    {showAllHistory ? 'Show less' : `Show all (${prediction.history.length})`}
                  </button>
                )}

                <button 
                  onClick={handleDelete} 
                  className="delete-prediction-btn"
                  style={{ marginTop: '20px' }}
                  disabled={loading}
                >
                  Delete Prediction
                </button>
              </div>
            )}
          </>
        ) : (
          <>
            <h4>Prediction History:</h4>
            <ul className="history-list">
              {[...displayedHistory].reverse().map((entry, index) => (
                <li key={index}>
                  <strong>{entry.chance}%</strong> on {formatDate(entry.date)}
                </li>
              ))}
            </ul>
            {hasMoreHistory && (
              <button 
                onClick={() => setShowAllHistory(!showAllHistory)} 
                className="show-all-btn"
              >
                {showAllHistory ? 'Show less' : `Show all (${prediction.history.length})`}
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default PredictionDetails;