import React, { useState, useEffect, useCallback } from 'react';
import { Route, Routes, useNavigate, Navigate } from 'react-router-dom';
import './App.css';
import { useAuth } from './context/AuthContext';
import PredictionForm from './components/PredictionForm';
import PredictionList from './components/PredictionList';
import PredictionDetails from './components/PredictionDetails';
import SharedPrediction from './components/SharedPrediction';
import ProfileNav from './components/ProfileNav';
import Profile from './components/Profile';
import LoginPage from './components/LoginPage';
import RegisterPage from './components/RegisterPage';
import ForgotPassword from './components/ForgotPassword';
import EmailVerification from './components/EmailVerification';
import LegalPage from './components/LegalPage';
import Footer from './components/Footer';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const App = () => {
  const { currentUser } = useAuth();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [predictions, setPredictions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const fetchPredictions = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      console.log('Attempting to fetch predictions with token:', token ? 'Token exists' : 'No token');
      console.log('Current auth state:', {
        isAuthenticated,
        emailVerified: currentUser?.emailVerified,
        userExists: !!currentUser
      });
      
      if (!token) {
        console.log('No token found, skipping fetch');
        setLoading(false);
        return;
      }

      console.log('Making fetch request to:', `${API_BASE_URL}/predictions`);
      const response = await fetch(`${API_BASE_URL}/predictions`, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
        
      if (!response.ok) {
        console.error('Server response not ok:', response.status, response.statusText);
        const errorData = await response.text();
        console.error('Error response body:', errorData);
        throw new Error(`Server responded with ${response.status}: ${errorData}`);
      }
        
      const data = await response.json();
      console.log('Successfully fetched predictions:', data);
      console.log('Prediction data format check:', {
        firstPredictionFormat: data[0] ? {
          hasCurrentChance: 'currentChance' in data[0],
          hasSnakeCase: 'current_chance' in data[0],
          actualValue: data[0].currentChance || data[0].current_chance
        } : 'No predictions'
      });
      setPredictions(data);
    } catch (err) {
      console.error('Full error details:', err);
      setError(err.message);
      console.error('Stack trace:', err.stack);
    } finally {
      setLoading(false);
    }
  }, [currentUser, isAuthenticated]); // Added dependencies

  // Check authentication when currentUser changes
  useEffect(() => {
    const checkAuth = async () => {
      console.log('Starting checkAuth, currentUser:', currentUser ? 'exists' : 'null');
      if (currentUser) {
        try {
          const token = await currentUser.getIdToken();
          console.log('Got new token:', token ? 'token exists' : 'no token');
          localStorage.setItem('token', token);
          setIsAuthenticated(true);
          console.log('User authenticated successfully');
        } catch (error) {
          console.error('Error getting token:', error);
        }
      } else {
        console.log('No current user, removing token');
        localStorage.removeItem('token');
        setIsAuthenticated(false);
      }
    };
    
    checkAuth();
  }, [currentUser]);

  // Extract complex condition into a variable
  const shouldFetchPredictions = isAuthenticated && currentUser?.emailVerified;

  // Fetch predictions when auth state changes
  useEffect(() => {
    if (shouldFetchPredictions) {
      fetchPredictions();
    } else {
      setPredictions([]);
      setLoading(false);
    }
  }, [shouldFetchPredictions, fetchPredictions]);

  const handleAuthChange = (newAuthState) => {
    setIsAuthenticated(newAuthState);
    if (!newAuthState) {
      setPredictions([]);
      setError(null);
    }
  };

  const ProtectedRoute = ({ children }) => {
    if (loading) return <div>Loading...</div>;
    if (!currentUser) return <Navigate to="/login" />;
    if (!currentUser.emailVerified) return <Navigate to="/verify-email" />;
    if (!localStorage.getItem('token')) return <Navigate to="/login" />;
    return children;
  };

  const addPrediction = async (newPrediction) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/predictions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(newPrediction),
      });

      if (!response.ok) {
        throw new Error('Failed to create prediction');
      }

      const createdPrediction = await response.json();
      setPredictions(prevPredictions => [...prevPredictions, createdPrediction]);
      navigate(`/prediction/${createdPrediction.id}`);
    } catch (err) {
      setError(err.message);
      console.error('Error creating prediction:', err);
    }
  };

const updatePrediction = async (updatedPrediction) => {
  const token = localStorage.getItem('token');
  const response = await fetch(`${API_BASE_URL}/predictions/${updatedPrediction.id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    body: JSON.stringify({
      currentChance: updatedPrediction.current_chance,
      resolved: updatedPrediction.resolved,
      resolution_value: updatedPrediction.resolution_value,
      resolved_at: updatedPrediction.resolved_at
    }),
  });

  const data = await response.json();
  
  if (!response.ok) {
    // For rate limit errors, include the minutes remaining
    if (response.status === 429) {
      throw new Error(`Please wait ${data.minutesRemaining} minute(s) before updating again`);
    }
    throw new Error(data.error || 'Failed to update prediction');
  }
  
  return data;
};

  const deletePrediction = async (id) => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API_BASE_URL}/predictions/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to delete prediction');
      }

      setPredictions(prevPredictions => 
        prevPredictions.filter(p => p.id !== id)
      );
    } catch (err) {
      setError(err.message);
      console.error('Error deleting prediction:', err);
    }
  };

  if (loading && window.location.pathname !== '/login' && window.location.pathname !== '/register') {
    return <div>Loading predictions...</div>;
  }

  if (error && isAuthenticated) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="App">
      {currentUser && currentUser.emailVerified && <ProfileNav />}
      <div className="content-wrapper">
      <Routes>
        <Route path="/login" element={<LoginPage setIsAuthenticated={handleAuthChange} />} />
        <Route path="/register" element={<RegisterPage setIsAuthenticated={handleAuthChange} />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/verify-email" element={<EmailVerification />} />
        <Route path="/share/:shareId" element={<SharedPrediction predictions={predictions} />} />
        
        {/* Protected routes */}
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <>
                <PredictionForm addPrediction={addPrediction} />
                <PredictionList predictions={predictions} deletePrediction={deletePrediction} />
              </>
            </ProtectedRoute>
          }
        />
        <Route
          path="/prediction/:id"
          element={
            <ProtectedRoute>
              <PredictionDetails 
                predictions={predictions} 
                setPredictions={setPredictions}
                updatePrediction={updatePrediction} 
                deletePrediction={deletePrediction} 
              />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <Profile setIsAuthenticated={handleAuthChange} />
            </ProtectedRoute>
          }
        />
        <Route path="/legal/:type/:lang?" element={<LegalPage />} />

      </Routes>
      </div>
    <Footer />      
    </div>
  );
};

export default App;